import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {buildValidations} from 'ember-cp-validations';

const Validations = buildValidations({});

export default class PromotionModel extends Model.extend(Validations) {
  @attr('number') bonus_id;
  @attr('number', { defaultValue: 0 }) order;
  @attr('number') status;
  @attr('string') type;
  @attr('date') updated_at;
  @attr('number') updated_by;

  @attr('boolean', { defaultValue: false }) _isPromotionUpdate;

  @belongsTo('bonus', { async: false, inverse: null }) bonus;

  @hasMany('promotion-by-language', { async: false, inverse: null }) promotionByLanguages;

  get bonusCode() {
    if (this.bonus && this.bonus.get('bonus_code')) {
      return this.bonus.get('bonus_code');
    }
    return null;
  }

  get title() {
    if (this.bonus && this.bonus.get('bonus_code')) {
      return this.bonus.get('bonus_code');
    }
    return null;
  }

  toJSON() {
    return {
      bonus_id: this.bonus_id,
      type: this.type,
      status: this.status,
      order: this.order,
    };
  }
}
